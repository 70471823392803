// src/App.js

import React,{ useState }  from 'react';
import { HashRouter} from 'react-router-dom';
import './App.css';
import Tab from './tab/Tab';
import Login from './login/Login';

function App() {
    const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('token'));
    const handleLogin = () => {
        setIsLoggedIn(!!localStorage.getItem('token'));
    };
    if (!isLoggedIn) {
        return <Login onLogin={handleLogin} />;
    }

    console.log( 'logged!')
    return (
        <HashRouter>
            <div className="App">
                <Tab onLogout={handleLogin}/>
            </div>
        </HashRouter>
    );
}

export default App;
