// config.js
const config = {
    development: {
        apiUrl: 'http://localhost:5000', // Local API URL
    },
    production: {
        apiUrl: 'https://chat-retrieval-api.opus-software.com.br', // Cloud API URL
    },
};

config.apiUrl = config[process.env.NODE_ENV].apiUrl;

export default config;
