// src/tab/Tab.js
import React from 'react';
import { NavLink, Route, Routes, useMatch } from 'react-router-dom';
import CLTeImpostos from '../clteimpostos/CLTeImpostos';
import RetrievalChat from '../retrieval-chat/RetrievalChat';
import Conversations from '../conversations/Conversations';
import Documents from '../documents/Documents';
import { useNavigate } from 'react-router-dom';
import './Tab.css';

function Tab({ onLogout }) {
    const user = localStorage.getItem('user');
    const navigate = useNavigate();
    function interceptLogout() {
        onLogout()
        navigate('/retrieval');
    }

    const handleLogout = async () => {
        // eslint-disable-next-line no-restricted-globals
        event.preventDefault();
        localStorage.removeItem('token');
        onLogout()
        navigate('/retrieval');
    }

    const hello =  (<div className={"user-box"}>
        <button onClick={handleLogout}>
            <img src={process.env.PUBLIC_URL + '/icons8-logout-50.png'} alt={"Logout"}
            ></img>
        </button>
        <div className={"user-name"}>
        {localStorage.getItem('user')}
        </div>
        </div>)

    let matchRetrieval = useMatch("/retrieval");
    let matchRetrievalChat = useMatch("/retrieval-chat");
    let matchConversations = useMatch("/conversations");
    let matchDocuments = useMatch("/documents");


    if (user === 'Demo') {
        return (
                <div className={"tab-container"}>
                    <nav>
                        {hello}
                        <NavLink to="/retrieval" className={matchRetrieval ? "active" : ""}>Retrieval QA</NavLink>
                    </nav>
                    <div className={"Routes"}>
                        <Routes>
                            <Route path="/retrieval" element={<CLTeImpostos onLogout={interceptLogout} />} />
                        </Routes>
                    </div>
                </div>
            );
    } else {
        return (
            <div className={"tab-container"}>
                <nav>
                    {/*<NavLink to="/retrieval" className={matchRetrieval ? "active" : ""}>Retrieval QA</NavLink>*/}
                    <NavLink to="/retrieval-chat" className={matchRetrievalChat ? "active" : ""} title={"Retrieval QA"}>
                        <img src="/icons8-answer-50.png"/>
                    </NavLink>
                    <NavLink to="/conversations" className={matchConversations ? "active" : ""} title={"Chat comercial"}>
                        <img src="/icons8-chat-bot-64.png"/>
                    </NavLink>
                    <NavLink to="/documents" className={matchDocuments? "active" : ""} title={"Petição"}>
                        <img src="/icons8-petition-48.png"/>
                    </NavLink>
                    <div className={"user-name"}>
                        Olá,<br/>{localStorage.getItem('user')}!
                        <button className={"button-exit"} onClick={handleLogout} title={"logout"}>
                            <img src={process.env.PUBLIC_URL + '/icons8-logout-50.png'} alt={"Logout"}
                            ></img>
                        </button>
                    </div>
                </nav>
                <div className={"Routes"}>
                    <Routes>
                        {/*<Route path="/retrieval" element={<CLTeImpostos onLogout={interceptLogout} />} />*/}
                        <Route path="/retrieval-chat" element={<RetrievalChat onLogout={interceptLogout} />} />
                        <Route path="/conversations" element={<Conversations onLogout={interceptLogout} />} />
                        <Route path="/documents" element={<Documents onLogout={interceptLogout} />} />
                    </Routes>
                </div>
            </div>
                );
    }
}

export default Tab;
