// src/clteimpostos/CLTeImpostos.js

import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import config from '../config';
import '../App.css';
import styles2 from './RetrievalChat.module.css';

// 'npm start' sets NODE_ENV to 'development' and 'npm run build' sets NODE_ENV to 'production'
const environment = process.env.NODE_ENV;
const apiUrl = config[environment].apiUrl;

function RetrievalChat( {onLogout}) {
    const [question, setQuestion] = useState('');
    const [loading, setLoading] = useState(false);
    const [store, setStore] = useState(null);
    const [stores, setStores] = useState([]);
    const [model, setModel] = useState('');
    const [chatId, setChatId] = useState(null);
    const [models, setModels] = useState([]);
    const [loadingPage, setLoadingPage] = useState(true);
    const [sessionHistory, setSessionHistory] = useState([[]]);
    const [message, setMessage] = useState('');

    const textareaRef = useRef(null);




    const handleOptionChange = (e) => {
        setModel(e.target.value);
    };
    const handleStoreOptionChange = (e) => {
        setStore(stores.find( x=> x.name == e.target.value));
    };

    const focus = () => {
        try {
            textareaRef.current.focus(); // Autofocus the textarea
        } catch {}
    }


    useEffect(() => {
        setLoadingPage(true);
        Promise.all([fetchModels(), fetchStores()])
            .then(() => {
                setLoadingPage(false)
                setTimeout( () => {
                    focus();
                }, 100);
            })
            .catch((error) => console.error('Failed to fetch data', error));
    }, []);

    const fetchModels = async () => {
        try {
            const response = await axios.get(apiUrl+ '/api/v1/models', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            setModels(response.data.models);
            setModel(response.data.models.find(m=>m=="gpt-4-1106-preview") || response.data.models[0]) // Set the first model as the default
        } catch (error) {
            handleAuthError( error)
            throw error; // Throw the error so Promise.all can catch it
        }
    };

    const fetchStores = async () => {
        try {
            const response = await axios.get(apiUrl+ '/api/v2/stores', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            setStores(response.data.stores);
            setStore(response.data.stores[0]); // Set the first model as the default
        } catch (error) {
            handleAuthError( error)
            throw error; // Throw the error so Promise.all can catch it
        }
    };


    const handleAuthError = (error) => {
        try {
            if (Math.round( error.response.status / 100) === 4) {
                localStorage.removeItem('token');
                onLogout()
            }
        } catch ( e) {
        }
    }

    const handleNewChat = () => {
        let newSessionHistory = [...sessionHistory];
        newSessionHistory.unshift([])
        setSessionHistory( newSessionHistory);
        setChatId(null);
    }

    const addEntryToHistory = ( role, msg) => {
        console.log( 'adding> ', role, msg);
        console.log( 'before> ', sessionHistory);
        let newSessionHistory = [...sessionHistory]; // create a copy
        newSessionHistory[0].unshift({
            role: role,
            msg: msg,
            timestamp: new Date().toLocaleString(),
            seq: newSessionHistory[0].length + 1});
        setSessionHistory( newSessionHistory);
        console.log( 'after added> ', newSessionHistory);
    }

    const handleSubmit = async () => {
        setLoading(true); // Show loading symbol
        setMessage( '')
        try {
            addEntryToHistory( "user", question);
            const response = await axios.post(apiUrl+ '/api/v2/retrieve-chat',
                { question, "store": store.name, model, "chat_id": chatId }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            console.log( response)
            addEntryToHistory( "assistant", JSON.parse(response.data.answer));
            if (chatId == null) {
                setChatId( response.data.chat_id);
            }

            setQuestion('');
            focus();
            console.log( sessionHistory);

        } catch (error) {
            console.error(error);
            handleAuthError( error)
            try {
                setMessage( error.response.data.error || error.response.statusText);
            } catch( innerError) {
                setMessage( error.message);
            }
        } finally {
            setLoading(false); // Hide loading symbol after request is returned
        }
    };

    const user = localStorage.getItem('user');


    if (loadingPage) {
        return (<div>Loading...</div>)
    } else {
        return (
            <div className={"page-wrapper"}>
                <div className={"page-body"}>
                    {user !== 'Demo' && store && (
                        <div className={"options-container"}>
                            <label htmlFor="store">Data store:</label>
                            <select value={store.name} onChange={handleStoreOptionChange}>
                                {stores.map((s) => (
                                    <option key={s.name} value={s.name}>
                                        {s.description}
                                    </option>
                                ))}
                            </select>
                        </div>
                    )}
                    {store &&
                        <h2>Esclarecimento de dúvidas sobre {store.description}</h2>}

                    <div>
                        {user !== 'Demo' && (
                            <div className={"options-container"}>
                                <label htmlFor="model">Model:</label>
                                <select value={model} onChange={handleOptionChange}>
                                    {models.map((model) => (
                                        <option key={model} value={model}>
                                            {model.replace(/\s*\([^)]*\)/g, '')}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        )}
                        <div>
                            <div>
                                <button className={"submit-button"} onClick={handleNewChat}>Novo Chat</button>
                            </div>
                            <textarea onInput= {e => {
                                e.target.style.height = 'auto';
                                e.target.style.height = e.target.scrollHeight + 'px';
                            }}
                                className={styles2["question-input"]}
                                value={question}
                                onChange={e => setQuestion(e.target.value)}
                                placeholder={chatId ? "mais alguma dúvida?" : "Qual é a sua dúvida?"}
                                ref={textareaRef}/>
                        </div>
                        <div>
                            <button className={"submit-button"} onClick={handleSubmit}>Submit</button>
                            {loading && <span>Loading...</span>}
                        </div>
                    </div>
                    {message && <div className={styles2["error-message"]}>
                        <p>Oops erro! {message}</p>
                    </div>}
                    <div className="history">
                        {sessionHistory.map((session, i) => (
                            <div key={i}>
                                {i > 0 && <hr className={styles2["separator"]}/>}
                                <div className={styles2["conversation"]}>
                                    {session.map((conversation, j) => (
                                        <div key={j} className={styles2["conversation-line"]}>
                                            <p className={styles2[conversation.role]}>
                                                <span className={styles2["role"]}> {conversation.role}:</span>
                                                {conversation.msg}
                                            </p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}

export default RetrievalChat;
