import React, { useState } from 'react';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import config from "../config";
import '../App.css';
import './Login.css';

const environment = process.env.NODE_ENV;
const apiUrl = config[environment].apiUrl;


function Login({ onLogin }) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');

    const handleSubmit = async () => {
        setLoading(true); // Show loading symbol
        try {
            const response = await axios.post(apiUrl + '/login', { username, password });
            const token = response.data.access_token;
            const decoded = jwtDecode(token);
            localStorage.setItem('token', token);
            localStorage.setItem('user', decoded.sub);
            onLogin();
        } catch (error) {
            console.error(error);
            try {
                setMessage( error.response.statusText);
            } catch( innerError) {
                setMessage( error.message);
            }
        } finally {
            setLoading(false); // Hide loading symbol after request is returned
        }
    };

    return (
        <div className={"page-wrapper"}>
            {/*<h2>Esclarecimento de dúvidas sobre as leis trabalhistas</h2>*/}
            <div className={"login-container"}>
            <div>
                <label>Username:</label>
                <input value={username} onChange={e => setUsername(e.target.value)} />
                <label>Password:</label>
                <input type="password" value={password} onChange={e => setPassword(e.target.value)} />
                <button onClick={handleSubmit}>Login</button>
                {loading && <span>Loading...</span>}
                <div>
                    <p>{message}</p>
                </div>
            </div>
            </div>
        </div>
    );
}

export default Login;
