import styles from "./Modal.module.css"
// import React, {useContext, useEffect, useState} from "react";
// import {ThemeContext} from "../../redux/ThemeContext";
// import { AppContext } from '../../redux/AppContext'; // import AppContext
// import {setUseMaximize} from "../../redux/actions";

function Modal({ handleClose, show, children, closeLabel }) {
    // const [useMaximize, setMaximize] = useState(false);
    // const { theme } = useContext(ThemeContext);
    // const { state, dispatch } = useContext(AppContext);
    // const { isMobile, useMaximize } = state;

    // useEffect(()=> {
    //     if (isMobile) {
    //         dispatch(setUseMaximize(true));
    //     }
    // }, [isMobile])

    const showHideClassName = show ? `${styles["modal"]} ${styles["display-block"]}` :
        `${styles["modal"]} ${styles["display-none"]}`;

    return (
        <div className={showHideClassName} onMouseDown={handleClose}>
            <div className={styles["modal-container-maximize"]}
                 onMouseDown={(e)=> e.stopPropagation()}>
                <div className={styles["modal-nav"]}>
                    <div className="icon-button">
                        <a onClick={handleClose}>
                            <img
                                src ="/icons8-close-50-light.png"
                            />
                        </a>
                    </div>
                </div>
                <div className={styles["modal-container-inner"]}>
                    {children}
                </div>
                {/*<div className={styles["modal-actions"]}>*/}
                {/*    /!*<button onClick={handleClose}>{closeLabel || "Cancel"}</button>*!/*/}
                {/*    {handleSave && <button onClick={handleSave}  className="button">Save</button>}*/}
                {/*</div>*/}
            </div>
        </div>
    );
};

export default Modal;

