import React, {useEffect, useState} from 'react';
import styles from './DocumentPetition.module.css';
import axios from 'axios';
import config from '../config';
import Modal from "../modal/Modal";

function DocumentPetition({documentId, backCallback}) {
    const [chatId, setChatId] = useState(null)
    const [petitionData, setPetitionData] = useState([])
    const [documentText, setDocumentText] = useState(null)
    const [showDocumentText, setShowDocumentText] = useState(false)
    const [loading, setLoading] = useState(false);
    const [estimatePrice, setEstimatePrice] = useState(null)

    useEffect( () => {
        loadPetitionData()
        loadDocumentText()
    }, [])


    const loadPetitionData = async () => {
        const response = await axios.get(config.apiUrl + `/api/document/${documentId}/petition_data`, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        });
        setPetitionData(response.data.data);
        setEstimatePrice(response.data?.estimate_price);
    }


    const loadDocumentText = async (_chatId) => {
        if (!documentText) {
            const response = await axios.get(config.apiUrl + `/api/document/${documentId}/text`, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            setDocumentText(response.data?.text);
        }
    }

    return (
        <div className={styles["main-container"]}>
            <div  className={styles["body-container"]}>
                <div className={styles["main-section-left-container"]}>
                    <h3>Petition Data</h3>
                    <div className={styles["back-button"]}>
                        <button onClick={backCallback} className={styles["button-with-icon"]}>
                            <img src="/icons8-back-50.png"/>back to list
                        </button>
                    </div>
                    <div className={styles['meta']}>
                        Estimate price: U$ {estimatePrice}
                    </div>
                    <div className={styles["petition-data"]}>
                        {Object.entries(petitionData).map(([key, value]) => (
                            <div className={styles["petition-data-row"]}>
                                <div className={styles["petition-data-key"]}>{key}</div>
                                <div className={styles["petition-data-value"]}>{value}</div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className={styles["main-section-right-container"]}>
                    <div className={styles["document-text"]}>
                        {documentText}
                    </div>
                </div>)
            </div>
        </div>
    )
}

export default DocumentPetition;
