// src/clteimpostos/CLTeImpostos.js

import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import config from '../config';
import '../App.css';
import './CLTeImpostos.css';

// 'npm start' sets NODE_ENV to 'development' and 'npm run build' sets NODE_ENV to 'production'
const environment = process.env.NODE_ENV;
const apiUrl = config[environment].apiUrl;

function CLTeImpostos( {onLogout}) {
    const [question, setQuestion] = useState('');
    const [answer, setAnswer] = useState('');
    const [loading, setLoading] = useState(false);
    const [store, setStore] = useState(null);
    const [stores, setStores] = useState([]);
    const [model, setModel] = useState('');
    const [models, setModels] = useState([]);
    const [sessionHistory, setSessionHistory] = useState([]);
    const [message, setMessage] = useState('');
    const [loadingPage, setLoadingPage] = useState(true);

    const textareaRef = useRef(null);

    const handleOptionChange = (e) => {
        setModel(e.target.value);
    };
    const handleStoreOptionChange = (e) => {
        setStore(stores.find( x=> x.name == e.target.value));
    };

    useEffect(() => {
        setLoadingPage(true);
        Promise.all([fetchModels(), fetchStores()])
            .then(() => {
                setLoadingPage(false)
                setTimeout( () => {
                    focus();
                }, 100);
            })
            .catch((error) => console.error('Failed to fetch data', error));
    }, []);

    const fetchModels = async () => {
        try {
            const response = await axios.get(apiUrl+ '/api/v1/models', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            setModels(response.data.models);
            setModel(response.data.models[0]); // Set the first model as the default
        } catch (error) {
            handleAuthError( error)
            throw error; // Throw the error so Promise.all can catch it
        }
    };

    const fetchStores = async () => {
        try {
            const response = await axios.get(apiUrl+ '/api/v2/stores', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            setStores(response.data.stores);
            setStore(response.data.stores[0]); // Set the first model as the default
        } catch (error) {
            handleAuthError( error)
            throw error; // Throw the error so Promise.all can catch it
        }
    };


    const handleAuthError = (error) => {
        try {
            if (Math.round( error.response.status / 100) === 4) {
                localStorage.removeItem('token');
                onLogout()
            }
        } catch ( e) {
        }
    }

    const focus = () => {
        try {
            textareaRef.current.focus(); // Autofocus the textarea
        } catch {}
    }
    const handleSubmit = async () => {
        setLoading(true); // Show loading symbol
        setMessage( '')
        try {
            const response = await axios.post(apiUrl+ '/api/v2/retrieve',
                { question, "store": store.name, model }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            console.log( response)
            const updatedSessionHistory = [...sessionHistory];
            updatedSessionHistory.unshift({
                question,
                answer: JSON.parse(response.data.answer),
                meta: response.data.meta,
                timestamp: new Date().toLocaleString(),
                seq: updatedSessionHistory.length + 1});
            setSessionHistory(updatedSessionHistory);

            // setAnswer(response.data.answer);
            setAnswer(JSON.parse(response.data.answer));
            focus();
        } catch (error) {
            console.error(error);
            handleAuthError( error)
            try {
                setMessage( error.response.statusText);
            } catch( innerError) {
                setMessage( error.message);
            }
        } finally {
            setLoading(false); // Hide loading symbol after request is returned
        }
    };

    const user = localStorage.getItem('user');
    if (loadingPage) {
        return (<div>Loading...</div>)
    } else {

        return (
            <div className={"page-wrapper"}>
                <div className={"page-body"}>
                    {user !== 'Demo' && store && (
                        <div className={"options-container"}>
                            <label htmlFor="store">Data store:</label>
                            <select value={store.name} onChange={handleStoreOptionChange}>
                                {stores.map((s) => (
                                    <option key={s.name} value={s.name}>
                                        {s.description}
                                    </option>
                                ))}
                            </select>
                        </div>
                    )}
                    {store &&
                        <h2>Esclarecimento de dúvidas sobre {store.description}</h2>}

                    <div>
                        {user !== 'Demo' && (
                            <div className={"options-container"}>
                                <label htmlFor="model">Model:</label>
                                <select value={model} onChange={handleOptionChange}>
                                    {models.map((model) => (
                                        <option key={model} value={model}>
                                            {model.replace(/\s*\([^)]*\)/g, '')}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        )}
                        <div>
                        <textarea
                            className={"question-input"}
                            value={question}
                            onChange={e => setQuestion(e.target.value)}
                            placeholder={"Qual é a sua dúvida?"}
                            ref={textareaRef}/>
                        </div>
                        <div>
                            <button className={"submit-button"} onClick={handleSubmit}>Submit</button>
                            {loading && <span>Loading...</span>}
                        </div>
                    </div>
                    <div className={"preformatted"}>
                        <p>{answer}</p>
                    </div>
                    <div>
                        <p>{message}</p>
                    </div>
                    <div className="history">
                        {sessionHistory.map((session, index) => (
                            <div key={index}>
                                <hr/>
                                <p><strong>{session.seq} {session.timestamp}</strong></p>
                                <p><strong>Question:</strong> {session.question}</p>
                                <p><strong>Answer:</strong> {session.answer}</p>
                                {user !== 'Demo' && (
                                    <p><strong>Meta:</strong> {session.meta}</p>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}

export default CLTeImpostos;
