import React, {useEffect, useState} from 'react';
import styles from './Documents.module.css';
import axios from 'axios';
import config from '../config';
import DocumentChat from "../documentChat/DocumentChat";
import DocumentPetition from "../documentDetail/DocumentPetition"

const environment = process.env.NODE_ENV;
const apiUrl = config[environment].apiUrl;


function Documents({ onLogout }) {
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [view, setView] = useState("list")
    const [uploadedFiles, setUploadedFiles] = useState([
        { id: 1, name: 'file1.txt', status: 'Uploaded' },
        { id: 2, name: 'file2.txt', status: 'Processing' },
        // Add more test data here
    ]);
    const [documents, setDocuments] = useState([])
    const [selectedDocumentId, setSelectedDocumentId] = useState([])
    const [reloadCount, setReloadCount] = useState(16)
    const [forceReload, setForceReload] = useState(0)
    const [loading, setLoading] = useState(false)

    useEffect(()=> {
        loadDocuments();
    },[])

    useEffect(()=> {
        if (forceReload> 0) {
            setTimeout( ()=> {
                loadDocuments();
            }, 5000)
        }
    },[forceReload])


    const handleAuthError = (error) => {
        if (Math.round( error.response.status / 100) === 4) {
            localStorage.removeItem('token');
            onLogout()
        }
    }


    const handleFileChange = (event) => {
        setSelectedFiles(event.target.files);
    };

    const handleUpload = async () => {
        setReloadCount(16);  //reset reload count\
        setLoading(true);
        for (let i = 0; i < selectedFiles.length; i++) {
            const file = selectedFiles[i];
            const formData = new FormData();
            formData.append('file', file);

            console.log( 'call setReloadCount(5)' );
            try {
                const response = await axios.post(apiUrl + '/api/document/upload', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                });
                setLoading(false);
                if (response.status === 200 || response.status===201) {
                    // setReloadCount(prevCount => 5);
                    await loadDocuments();
                    // setUploadedFiles(prevFiles => [
                    //     ...prevFiles,
                    //     {id: prevFiles.length + 1, name: file.name, status: 'Uploaded'}
                    // ]);
                } else {
                    console.error('Failed to upload file:', response);
                }
            } catch (error) {
                console.error('An error occurred while uploading the file:', error);
            }
        }
    };

    const handleDelete = async(_id) => {
        if (window.confirm( 'delete this document?')) {
            await axios.delete(apiUrl + `/api/document/${_id}`, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            await loadDocuments();
        }
    }

    const loadDocuments = async () => {
        console.log( 'loading documents..')
        try {
            const response = await axios.get(apiUrl + '/api/document', {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            window._docs = response
            setDocuments(response.data);

            let is_collecting = false
            for (let i = 0; i< response.data.length; i++) {
                const doc = response.data[i];
                if (!doc?.petition_data_collected) {
                    is_collecting=true
                    console.log( JSON.stringify(doc));
                    break
                }
            }
            console.log( 'reloadCount', reloadCount, 'is_collecting', is_collecting)
            if (reloadCount> 0 && is_collecting) {
                setForceReload(forceReload+1)
                // setTimeout(()=> {
                //     setForceReload(forceReload+1)
                // }, 1000)
                // console.log( 'set reload count to ', reloadCount-1);
                setReloadCount(prevCount => prevCount - 1); // Use functional update to ensure the latest state
                // setReloadCount( reloadCount-1)
            }

        } catch(error) {
            handleAuthError( error)
        }

    }
    const viewContent = (
        <div className={styles["page-container"]}>
            <h3>Document Upload</h3>
            <div className={styles['upload-panel']}>
                <input type="file" multiple onChange={handleFileChange} />
                <button onClick={handleUpload}>Upload file</button>
            </div>
            {loading && <div className={styles["uploading"]}>uploading...</div>}
            <div className={styles["header-row"]}>
                {/*<div>ID</div>*/}
                <div>File Name</div>
                <div>Size (bytes)</div>
                <div>Data collected</div>
                <div>Actions</div>
            </div>
            <div className={styles["table"]}>
                {documents.map((doc, index) => (
                    <div className={styles["data-row"]} key={index}>
                        <div  onClick={()=> {
                            setSelectedDocumentId( doc._id);
                            setView( "chat");
                        }} title='chat with this document' className={styles['file-name']}>{doc.file_name}</div>
                        <div className={styles['right']}>{doc.size.toLocaleString()}</div>
                        <div className={styles['center']}>{doc?.petition_data_collected && (
                            <button  className={styles["icon-button-inline"]} onClick={()=> {
                                setSelectedDocumentId( doc._id);
                                setView( "petition");
                            }} title={'view collected data'}>
                                <img className={styles["icon"]} src="/icons8-done-48.png"/>
                            </button>)}
                            {!doc?.petition_data_collected &&
                                <span className={styles["collecting"]}
                                    onClick={loadDocuments}
                                >collecting..</span>}
                        </div>
                        <div className={styles['center']}>
                            <button  className={styles["icon-button-inline"]} onClick={()=> {
                                handleDelete( doc._id);
                            }} title={'delete this document'}>
                                <img className={styles["icon"]} src="/icons8-delete-64.png"/>
                            </button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )

    return (<>
        {view == "list" && viewContent}
        {view == "chat" && <DocumentChat documentId = {selectedDocumentId} backCallback={()=>setView("list")}/>}
        {view == "petition" && <DocumentPetition documentId = {selectedDocumentId} backCallback={()=>setView("list")}/>}
        </>
    );
}

export default Documents;
