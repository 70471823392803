import React, {useEffect, useState} from 'react';
import styles from './DocumentChat.module.css';
import axios from 'axios';
import config from '../config';
import Modal from "../modal/Modal";

function DocumentChat( {documentId, backCallback}) {
    const [chatId, setChatId] = useState(null)
    const [chatTemplates, setChatTemplates] = useState([])
    const [chatHistory, setChatHistory] = useState([])
    const [prompt, setPrompt] = useState('')
    const [chatResponse, setChatResponse] = useState('')
    const [chatThread, setChatThread] = useState(null)
    const [showTemplateModal, setShowTemplateModal] = useState(false);
    const [templatePrompt, setTemplatePrompt] = useState('')
    const [templateName, setTemplateName] = useState('')
    const [documentText, setDocumentText] = useState(null)
    const [showDocumentText, setShowDocumentText] = useState(false)
    const [loading, setLoading] = useState(false);

    useEffect( () => {
        loadTemplates()
        loadChatHistory()
    }, [])

    useEffect(()=> {
        if (chatId) {
            loadChatThread();
        }
    }, [chatId])



    const loadTemplates = async () => {
        const response = await axios.get(config.apiUrl + '/api/document/chat_templates', {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        });
        setChatTemplates(response.data);
    }

    const loadChatHistory = async () => {
        const response = await axios.get(config.apiUrl + `/api/document/${documentId}/chats`, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        });
        setChatHistory(response.data);
    }

    const loadChatThread = async (_chatId) => {
        const response = await axios.get(config.apiUrl + `/api/document/${documentId}/chats/${_chatId || chatId}/thread`, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        });
        setChatThread(response.data);
    }

    const loadDocumentText = async (_chatId) => {
        if (!documentText) {
            const response = await axios.get(config.apiUrl + `/api/document/${documentId}/text`, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            setDocumentText(response.data?.text);
        }
    }


    const sendMessage= async () => {
        setLoading( true)
        const response = await axios.post(config.apiUrl + `/api/document/${documentId}/chat/message`,
            {
                message: prompt,
                chat_id: chatId,
                template_id: ''
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
        setLoading(false)
        setChatId( response.data.chat_id)
        await loadChatThread(response.data.chat_id);
        // setChatResponse( response.data.response)
    }

    const handleSaveTemplate = async() => {
        const response = await axios.post(config.apiUrl + `/api/document/chat_templates`,
            {
                prompt: templatePrompt,
                name: templateName
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
        setChatId( response.data.chat_id)
        await loadTemplates()
    }


    const divHeader = (
        <div>
            <h3>Document Chat</h3>
            <button onClick={backCallback} className={styles["button-with-icon"]}>
                <img src="/icons8-back-50.png"/>back to list
            </button>
            <div style={{display: "none"}}>Chat page for document {documentId}</div>
        </div>
    )

    const divHistory = (
        <div className={styles["history-container"]}>
            <h4>Últimas consultas</h4>
            <div className={styles['history-list']}>
                {chatHistory.map((chat, index)=> (
                    <div className={styles['history-title']} key={index} onClick={()=>setChatId(chat.chat_id)}>
                        {chat.title.substring(0,60)}
                    </div>
                ))}
            </div>
        </div>
    )

    const templateModal = showTemplateModal && (
        <Modal
            show={showTemplateModal}
            handleClose = {()=> setShowTemplateModal(false)}
        >
            <div className={styles["template-container"]}>
                Title: <input type={"text"}
                              value={templateName}
                              onChange = {e=> setTemplateName( e.target.value)}
                              placeholder={"Input the template title"}/>
                <textarea
                    autoFocus
                    value={templatePrompt}
                    onChange={e => setTemplatePrompt(e.target.value)}
                    placeholder={"Input the template prompt"}/>
                <button onClick={async () => {
                    await handleSaveTemplate()
                    setShowTemplateModal(false)
                }} className={styles["button-normal-size"]}>Save and Close</button>
            </div>

        </Modal>
    )


    const newChatDiv = !chatId && (
        <div className={styles['chat-thread']}>
            <div className={styles['template-panel']}>
                <label htmlFor="template">Templates:</label>
                <select value={''} onChange={(e) => setPrompt(e.target.value)}>
                    <option disabled value="" className={styles["project-switcher-option"]}>Select a pre-defined prompt</option>
                    {chatTemplates.map((template,index) => (
                        <option key={index} value={template.prompt}>
                            {template.name}
                        </option>
                    ))}
                </select>
                {/*<button onClick={()=> {setShowTemplateModal(true)}}>Add template</button>*/}
                <button
                    className={`${styles["button-with-icon"]} ${styles["button-normal-size"]}`}
                    onClick={()=> {setShowTemplateModal(true)}}
                ><img src="/icons8-plus-50.png"/>Add template</button>

            </div>
        </div>
    )

    const chatContinuationDiv = !!chatId && (
        <div className={styles['chat-thread']}>
            <div>
                continuation of chat {chatId}
            </div>
            <div className={styles['chat-thread-list']}>
                <div className={styles['chat-thread-list-inner']}>
                {chatThread?.entries?.map((entry,index) => (
                    <div className={styles['chat-row']}>
                        {/*<div>{entry.role}</div>*/}
                        <div className={`${styles['chat-thread-text']} ${styles[entry.role]}`}><span className={styles["role-name"]}>{entry.role}:</span>
                            {entry.content}
                        </div>
                        {entry.estimate_price && (<div className={styles["meta"]}>
                            <span>Estimate price: ${entry.estimate_price}</span>
                            <span>Model: {entry.model}</span>
                            <span className={styles["usage"]}>Usage: {JSON.stringify(entry.usage)}</span>
                        </div>)}
                    </div>
                ))}
                </div>
            </div>
        </div>
    )

    const chatInputPanel = (
        <div className={styles["chat-input-panel"]}>
            <textarea
                className={styles['chat-textarea']}
                value={prompt}
                onChange={e => setPrompt(e.target.value)}
            >
            </textarea>
            <button onClick={sendMessage} className={styles["icon-button"]}>
                <img className={loading? `${styles["loading-anim"]}`: ''} src="/icons8-right-arrow-50-light.png">
                </img>
            </button>
        </div>
    )

    const divMain = (
        <div className={styles["main-section-left-container"]}>
            <button
                className={`${styles["button-with-icon"]} ${styles["button-normal-size"]}`}
                onClick={()=> {
                    setChatId(null);
                }}
            ><img src="/icons8-plus-50.png"/>New Chat
            </button>
            {newChatDiv}
            {chatContinuationDiv}
            {chatInputPanel}
        </div>
    )

    const divDocument =  showDocumentText && (
        <div className={styles["main-section-right-container"]}>
            <div className={styles["document-text"]}>
                {documentText}
            </div>
        </div>)


    return (
        <div className={styles["main-container"]}>
            <div className={styles["header-container"]}>
                {divHeader}
                <div className={styles['show-document-panel']}>
                    {!showDocumentText && (
                        <a onClick={async () => {
                            await loadDocumentText();
                            setShowDocumentText(true);
                        }}> load document..</a>
                    )}
                    {showDocumentText && (
                        <a onClick={()=> setShowDocumentText(false)}> hide document..</a>
                    )}
                </div>
                {divHistory}
            </div>
            <div  className={styles["body-container"]}>
                {divMain}
                {divDocument}
            </div>
            {templateModal}
        </div>
    )
}

export default DocumentChat;
