// src/Conversations.js
import React, { useState } from 'react';
import axios from 'axios';
import config from '../config';
import styles1 from './Conversations.module.css'; // Update this line

const environment = process.env.NODE_ENV;
const apiUrl = config[environment].apiUrl;

function Conversations({ onLogout }) {
    const [date, setDate] = useState(new Date().toISOString().split('T')[0]); // Default to current date
    const [conversations, setConversations] = useState([]);

    const handleAuthError = (error) => {
        try {
            if (Math.round( error.response.status / 100) === 4) {
                localStorage.removeItem('token');
                onLogout()
            }
        } catch ( e) {
        }
    }


    const handleDateChange = (e) => {
        setDate(e.target.value);
    };

    const fetchConversations = async () => {
        try {
            console.log( localStorage.getItem('token'))
            const response = await axios.get(apiUrl + '/api/v1/conversations', {
                params: { date },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            setConversations(response.data.conversations);
        } catch (error) {
            console.error(error);
            handleAuthError( error)
        }
    };

    return (
        <div className="page-wrapper">
            <div className="page-body">
                <a style={{float:"right"}} href={ "/chat.html"} target={"_blank"} rel="noreferrer">ir para o Chat</a>
                <h2>Conversation Log</h2>

                <div>
                    <label>
                        Date:
                        <input type="date" value={date} onChange={handleDateChange} />
                    </label>
                    <button onClick={fetchConversations}>Fetch Conversations</button>
                </div>

                {conversations.map((session, i) => (
                    <div key={i}>
                        <h3>Session: {session._id}</h3>
                        <div className={styles1["conversation"]}>
                        {session.conversations.map((conversation, j) => (
                            <div key={j}  className={styles1["conversation-line"]}>
                                <p className={styles1[conversation.role]}>
                                    {/*{conversation.datetime}*/}
                                    <span className={styles1["role"]}> {conversation.role}:</span>
                                    {conversation.msg}
                                    </p>
                            </div>
                        ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Conversations;
